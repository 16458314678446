import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
  transform<T>(array: T[], field: keyof T): T[] {
    if (!Array.isArray(array) || !field) {
      return array;
    }
    return [...array].sort((a, b) => String(a[field]).localeCompare(String(b[field])));
  }
}
